import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './App.css'; // Link the CSS file

// --- Helper: Format Date/Time ---
const formatDateTime = (isoString, options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }) => {
    if (!isoString) return 'N/A';
    try {
        return new Date(isoString).toLocaleString(undefined, options);
    } catch (e) { console.error("Error formatting date:", e); return 'Invalid Date'; }
};
// --- Helper: Get Current Time formatted ---
const getCurrentTime = () => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    return new Date().toLocaleTimeString(undefined, options);
}

// --- Confetti Component ---
const ConfettiBurst = ({ count = 15 }) => {
    const pieces = [];
    for (let i = 0; i < count; i++) {
        pieces.push(<div key={i} className={`confetti-piece piece-${i}`}></div>);
    }
    return <div className="confetti-container">{pieces}</div>;
};


function App() {
    const [validationDetails, setValidationDetails] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState('');
    const [isDisplayingValidation, setIsDisplayingValidation] = useState(false);
    const [currentTime, setCurrentTime] = useState(getCurrentTime());

    const validationTimeoutRef = useRef(null);
    const clockIntervalRef = useRef(null);

    // --- Effect for Socket Connection ---
    useEffect(() => {
        console.log("--- Component Mounted: Setting up Socket ---");
        const SOCKET_URL = "http://harwarepanelapi.sudharsan.in:3001";
        const socket = io(SOCKET_URL, {
            reconnectionAttempts: 5,
            timeout: 10000,
            transports: ['websocket'],
         });

        const clearValidationDisplay = () => {
            if (validationTimeoutRef.current) clearTimeout(validationTimeoutRef.current);
            validationTimeoutRef.current = null;
            setIsDisplayingValidation(false);
            setValidationDetails(null);
        };

        // --- Socket Event Listeners (connect, disconnect, connect_error, onAny) ---
        socket.on('connect', () => {
            console.log('%cSocket connected:', 'color: green; font-weight: bold;', socket.id);
            setIsConnected(true); setError(''); clearValidationDisplay();
        });
        socket.on('disconnect', (reason) => {
             console.warn('%cSocket disconnected:', 'color: orange; font-weight: bold;', reason);
             setIsConnected(false); clearValidationDisplay();
             setError(reason === 'io server disconnect' ? 'System temporarily unavailable.' : 'Connection issue. Awaiting connection...');
         });
        socket.on('connect_error', (err) => {
             console.error('%cSocket connection error:', 'color: red; font-weight: bold;', err);
             setIsConnected(false); clearValidationDisplay();
             setError('Connection Error. Please contact support.');
         });
        socket.onAny((event, payload) => { console.log(`[WS DEBUG] Event: ${event}`, payload); });


        const eventName = "deviceNotification";
        socket.on(eventName, (message) => {
            console.log(`Received raw '${eventName}':`, message);
            // --- Data Validation Logic ---
            if (
                typeof message === 'object' && message !== null &&
                message.username && message.activation_date &&
                message.ticket_id && typeof message.ticket_count === 'number' &&
                message.now_time
            ) {
                console.log("Valid validation data received:", message);
                if (validationTimeoutRef.current) clearTimeout(validationTimeoutRef.current);

                setValidationDetails(message);
                setIsDisplayingValidation(true); // Trigger display and animation

                // --- Set Timeout to Hide ---
                validationTimeoutRef.current = setTimeout(() => {
                    console.log("Auto-clearing validation display.");
                    setIsDisplayingValidation(false);
                    setValidationDetails(null);
                    validationTimeoutRef.current = null;
                }, 8000); // Display for 8 seconds

            } else {
                console.warn(`Received '${eventName}' with invalid format or missing fields:`, message);
            }
        });

        return () => {
            // --- Cleanup Socket ---
            console.log("--- Component Unmounting: Disconnecting Socket ---");
            clearValidationDisplay();
            socket.disconnect();
        };
    }, []);

    // --- Effect for Clock ---
    useEffect(() => {
        clockIntervalRef.current = setInterval(() => setCurrentTime(getCurrentTime()), 1000);
        return () => { if (clockIntervalRef.current) clearInterval(clockIntervalRef.current); };
    }, []);

    // --- Kiosk Rendering Logic ---
    const renderKioskScreenContent = () => {
        // States 1 (Error) & 2 (Connecting)
        if (error && !isConnected) {
             return (
                <div className="kiosk-message error">
                    <div className="message-icon error-icon">⚠️</div>
                    <h2>System Error</h2><p>{error}</p>
                </div>
            );
         }
        if (!isConnected) {
             return (
                <div className="kiosk-message info">
                     <div className="message-icon connecting-icon">🔄</div>
                     <h2>Connecting...</h2><p>Initializing validation system.</p>
                 </div>
             );
         }

        // 3. Displaying Successful Validation
        if (isConnected && isDisplayingValidation && validationDetails) {
            return (
                // Add 'validated-active' class for confetti trigger
                <div className={`kiosk-display validated ${isDisplayingValidation ? 'validated-active' : ''}`}>
                    {/* --- Confetti --- */}
                    {isDisplayingValidation && <ConfettiBurst />}

                    <div className="validation-header">
                        <span className="validation-icon success-icon">✅</span>
                        <h2 className="validation-status">Validated</h2>
                    </div>
                    {/* --- Scrollable Content Area --- */}
                    <div className="validation-scroll-content">
                        <div className="validation-details">
                            {/* --- Detail Lines --- */}
                            <div className="detail-line"><span className="detail-label">User:</span><span className="detail-value username">{validationDetails.username}</span></div>
                            <div className="detail-line"><span className="detail-label">Ticket Type:</span><span className="detail-value ticket-type">{validationDetails.activation_date}</span></div>
                            <div className="detail-line"><span className="detail-label">Ticket Count:</span><span className="detail-value ticket-count">{validationDetails.ticket_count}</span></div>
                            <hr className="detail-separator" />
                            <div className="detail-line"><span className="detail-label">Ticket ID:</span><span className="detail-value ticket-id">{validationDetails.ticket_id}</span></div>
                            <div className="detail-line"><span className="detail-label">Validation Time:</span><span className="detail-value validation-time">{formatDateTime(validationDetails.now_time)}</span></div>
                            <div className="detail-line"><span className="detail-label">Entry Point:</span><span className="detail-value entry-point">Main Gate - Lane 3</span></div>
                            <div className="detail-line"><span className="detail-label">Event/Zone:</span><span className="detail-value event-zone">Tech Expo 2025 / Hall B</span></div>
                        </div>
                         <p className="footer-instruction">Access Granted. Please Proceed.</p>
                    </div> {/* End validation-scroll-content */}
                </div> // End kiosk-display validated
            );
        }

        // 4. Idle State
        if (isConnected && !isDisplayingValidation) {
             return (
                 <div className="kiosk-message idle">
                     <div className="message-icon idle-icon">(((📱)))</div>
                     <h2>Hands-Free Checkpoint</h2>
                     <p>Walk forward for seamless ticket validation.</p>
                 </div>
             );
         }

        // Fallback
        return <div className="kiosk-message info">Loading...</div>;
    };

    // --- Main Component Return ---
    return (
        <div className="kiosk-container">
            {/* --- Static Header --- */}
            <header className="kiosk-static-header">
                <span className="header-location">📍 Powered by Zed Digital</span>
                <span className="header-event">🎉 Event: Tech Expo 2025</span>
            </header>

            {/* --- Main Dynamic Screen --- */}
            <main className="kiosk-screen">
                {renderKioskScreenContent()}
            </main>

            {/* --- Static Footer --- */}
            <footer className="kiosk-static-footer">
                <span className="footer-kiosk-id"> Validator ID: ZIG001</span>
                 <span className="footer-status">System Status: <span className={isConnected ? 'status-ok' : 'status-error'}>{isConnected ? 'Online' : 'Offline'}</span> {error && !isConnected ? `(${error.substring(0, 30)}${error.length > 30 ? '...' : ''})` : ''}</span>
                <span className="footer-time">🕒 {currentTime}</span>
            </footer>
        </div>
    );
}

export default App;